<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-6">
			<div class="input-wrap">
				<h5 class="mb-2">Reporte de comprobantes</h5>
			</div>
			<div class="action-btn-wrap">
				<v-btn v-if="records.length > 0" color="accent" @click="clickDownloadExcel()">
					<v-icon left small>mdi-file-excel</v-icon> Exportar Excel
				</v-btn>
			</div>
		</div>
		<v-card color="secondary" class="pa-3">
			<v-row dense>
				<v-col cols="12" xl="3" lg="4" md="12" sm="12">
					<div class="form-group">
						<label class="control-label">Desde</label>
						<el-date-picker
							v-model="search.start"
							type="date"
							value-format="yyyy-MM-dd"
							format="dd/MM/yyyy"
							:picker-options="pickerOptions"
						></el-date-picker>
					</div>
				</v-col>
				<v-col cols="12" xl="3" lg="4" md="12" sm="12">
					<div class="form-group">
						<label class="control-label">Hasta</label>
						<el-date-picker
							v-model="search.end"
							type="date"
							value-format="yyyy-MM-dd"
							format="dd/MM/yyyy"
							:picker-options="pickerOptionsDates"
						></el-date-picker>
					</div>
				</v-col>
				<v-col cols="12" xl="2" lg="4" md="12" sm="12">
					<el-button
						type="primary"
						icon="el-icon-search"
						class="btn-block mt-5"
						:loading="loading"
						@click="getData"
					></el-button>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" xl="12" lg="12">
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-right">N°</th>
									<th>Fecha emisión</th>
									<th>Tipo Comprobante</th>
									<th>Número</th>
									<th>Doc. Afectado</th>
									<th>Cliente N°</th>
									<th>Cliente</th>
									<th>Estado</th>
									<th>Moneda</th>

									<th class="text-right">Total Gravado</th>
									<th class="text-right">Total IGV</th>
									<th class="text-right">Total</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(row, index) in records" :key="index">
									<td class="text-right">{{ index + 1 }}</td>
									<td>{{ row.date_of_issue }}</td>
									<td>{{ row.document_type }}</td>
									<td>{{ row.number_full }}</td>
									<td>{{ row.serie_affec }}</td>
									<td class="text-right">{{ row.customer_number }}</td>
									<td>{{ row.customer_name }}</td>
									<td>
										<span
											class="badge bg-secondary text-white"
											:class="{
												'bg-danger': row.state_type_id === '11',
												'bg-warning': row.state_type_id === '13',
												'bg-secondary': row.state_type_id === '01',
												'bg-info': row.state_type_id === '03',
												'bg-success': row.state_type_id === '05',
												'bg-secondary': row.state_type_id === '07',
												'bg-dark': row.state_type_id === '09',
											}"
											>{{ row.state_description }}</span
										>
									</td>
									<td>{{ row.currency_type_id }}</td>
									<td class="text-right">{{ row.total_taxed }}</td>
									<td class="text-right">{{ row.total_igv }}</td>
									<td class="text-right">
										<strong>{{ row.total }}</strong>
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="8"></td>
									<td><strong class="font-black">Totales $</strong></td>
									<td class="text-right table-success">
										<strong>{{ totals.acum_total_taxed_usd }}</strong>
									</td>
									<td class="text-right table-success">
										<strong>{{ totals.acum_total_igv_usd }}</strong>
									</td>
									<td class="text-right table-success">
										<strong>{{ totals.acum_total_usd }}</strong>
									</td>
								</tr>
								<tr>
									<td colspan="8"></td>
									<td><strong class="font-black">Totales S/</strong></td>
									<td class="text-right table-success">
										<strong>{{ totals.acum_total_taxed }}</strong>
									</td>
									<td class="text-right table-success">
										<strong>{{ totals.acum_total_igv }}</strong>
									</td>
									<td class="text-right table-success">
										<strong>{{ totals.acum_total }}</strong>
									</td>
								</tr>
							</tfoot>
						</template>
					</v-simple-table>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script>
import dayjs from 'dayjs';
export default {
	data() {
		return {
			resource: 'reports/documents',
			records: [],
			loading: false,
			totals: {
				acum_total_taxed: 0,
				acum_total_igv: 0,
				acum_total: 0,
				acum_total_exonerado: 0,
				acum_total_inafecto: 0,
				acum_total_free: 0,
				acum_total_taxed_usd: 0,
				acum_total_igv_usd: 0,
				acum_total_usd: 0,
			},
			search: {
				start: dayjs()
					.startOf('month')
					.format('YYYY-MM-DD'),
				end: dayjs().format('YYYY-MM-DD'),
			},
			pickerOptions: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') < time;
				},
			},
			pickerOptionsDates: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return this.search.start > time;
				},
			},
		};
	},
	created() {},
	methods: {
		async getData() {
			this.loading = true;
			await this.$http.post(`/${this.resource}/records`, this.search).then((response) => {
				this.records = response.data.records;
				this.totals = response.data.totals;

				this.loading = false;
			});
		},
		clickDownloadExcel() {
			this.$http({
				url: `/${this.resource}/excel`,
				method: 'GET',
				responseType: 'blob',
			}).then((response) => {
				var fileURL = window.URL.createObjectURL(new Blob([response.data]));
				var fileLink = document.createElement('a');

				fileLink.href = fileURL;
				fileLink.setAttribute('download', 'ReporteComprobantes.xlsx');
				document.body.appendChild(fileLink);

				fileLink.click();
			});
		},
	},
};
</script>
